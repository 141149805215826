// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { withTheme } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'

// Components
import BIcon from 'ui/blocks/Icon'

// Shared

library.add(far)

/**
 * Block: Icon.
 *
 * @example
 *   <Icon icon="save" style="far" />
 */

export class Icon extends Component {
  static displayName = 'Icon'

  static propTypes = {
    style: PropTypes.string,
    icon: PropTypes.any,
    className: PropTypes.string,
    theme: PropTypes.object,
    spin: PropTypes.bool,
    fw: PropTypes.bool,
    color: PropTypes.string,
    modifiers: PropTypes.string,
    svg: PropTypes.bool,
    rounded: PropTypes.bool,
    backgroundColor: PropTypes.string
  }

  static defaultProps = {
    fw: true,
    svg: false,
    rounded: false
  }

  render () {
    const { icon, spin, style, fw, className, svg, ...otherProps } = this.props
    if (!icon) return null

    if (svg) {
      return <FontAwesomeIcon icon={['far', icon]} />
    }

    const faStyle = style || this.props.theme.font.fontAwesomeStyle || 'far'
    const classNames = [faStyle, `fa-${icon}`]

    if (fw) classNames.push('fa-fw')
    if (className) classNames.push(className)

    spin && classNames.push('fa-spin')

    return (
      <BIcon className={classNames.join(' ')} {...otherProps} />
    )
  }
}

export default withTheme(Icon)
