import moment from 'moment'
import _intersection from 'lodash/intersection'

const days = {
  all: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
  weekday: ['mon', 'tue', 'wed', 'thu', 'fri'],
  weekend: ['sat', 'sun'],
  mon: ['mon'],
  tue: ['tue'],
  wed: ['wed'],
  thu: ['thu'],
  fri: ['fri'],
  sat: ['sat'],
  sun: ['sun']
}

function checkTimeSlot (openingHours) {
  return (timeslot) => {
    const operatingRule = openingHours.find((rule) => {
      const timeslotDays = days[timeslot.day]
      const ruleDays = days[rule.day]

      return (
        _intersection(timeslotDays, ruleDays).length > 0 &&
        moment(timeslot.from, 'HH:mm').isSameOrAfter(moment(rule.from, 'HH:mm'), 'second') &&
        moment(timeslot.till, 'HH:mm').isSameOrBefore(moment(rule.till, 'HH:mm'), 'second')
      )
    })

    if (operatingRule) return true

    return false
  }
}

export function timeslotsApplicable (timeslots, openingHours) {
  return timeslots.some(checkTimeSlot(openingHours))
}

export function allTimeSlotsApplicable (timeslots, openingHours) {
  return timeslots.every(checkTimeSlot(openingHours))
}
