// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BLoader from 'ui/blocks/Loader'
import Icon from 'ui/components/Icon'

// Shared

/**
 * Block: Loader.
 *
 * @example
 *   <Loader size="md" color="primary" />
 */
export default class Loader extends Component {
  static displayName = 'Loader'

  static propTypes = {
    size: PropTypes.string,
    color: PropTypes.string
  }

  render () {
    const { size, color } = this.props

    return (
      <BLoader size={size} color={color} data-tid="Loader">
        <Icon icon="spinner-third" spin size={size} />
      </BLoader>
    )
  }
}
