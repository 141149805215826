import _every from 'lodash/every'

export const FeatureFlags = {
  // Enable the delivery options
  DELIVERIES: 'deliveries',
  // Show a different powered by link and text
  EQUIPMENT_RENTAL_SOFTWARE_LINK: 'equipment_rental_software_link',
  // Enable address validation for all addresses
  NEW_ADDRESS_VALIDATION: 'new_address_validation',
  // Enable payment charges, authorizations & refunds
  NEW_PAYMENTS: 'new_payments'
}

/**
 * Checks if feature is enabled.
 */
export const featureEnabled = (...features) => {
  const state = (window.store || window.booqableGetStore?.())?.getState()
  const featuresArray = state.session?.enabledFeatures || state.client?.enabledFeatures

  return _every(features, (feature) => {
    return (featuresArray || []).includes(feature)
  })
}

/**
 * Checks if feature is disabled.
 */
export const featureDisabled = (...features) => !featureEnabled(...features)

/**
 * Back office only
 * Checks if feature is part of subscription.
 */
export const featureAllowed = (...features) => {
  const state = window.store?.getState()

  return _every(features, (feature) => {
    return (state.session.features || []).includes(feature)
  })
}
