// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import _isString from 'lodash/isString'

// Components
import BLink from 'ui/blocks/Link'
import Icon from 'ui/components/Icon'
import Span from 'ui/elements/Span'

// Shared
import { Routing as RoutingUtils } from 'back_office/v2/utils/utils'

/**
 * Link component
 *
 * @example
 *   <Link target="_blank" href="/settings/prices" />
 */
export default class Link extends Component {
  static displayName = 'Link'

  static propTypes = {
    href: PropTypes.string,
    target: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
      PropTypes.object
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    iconAppend: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.oneOf([
      'white',
      'primary',
      'dark',
      'danger'
    ]),
    iconColor: PropTypes.string,
    noBlankIcon: PropTypes.bool,
    padding: PropTypes.any,
    innerRef: PropTypes.any
  }

  static defaultProps = {
    color: 'primary',
    children: []
  }

  get icon () {
    const data = this.props.icon

    if (!data) return null

    if (_isString(data)) {
      return { icon: data }
    } else {
      return data
    }
  }

  render () {
    const {
      children,
      size,
      href,
      target,
      color,
      iconAppend,
      padding,
      iconColor,
      noBlankIcon,
      innerRef,
      ...otherProps
    } = this.props

    if (href && href.startsWith('/')) {
      // Link is an internal link
      otherProps.onClick = otherProps.onClick || RoutingUtils.navigateLink
    }

    if (otherProps.handleClose) {
      delete otherProps.handleClose
    }

    const hasChildren = children.length !== 0

    return (
      <BLink
        iconColor={iconColor}
        iconOnly={!hasChildren}
        iconAppend={iconAppend}
        padding={padding}
        color={color}
        size={size}
        href={href}
        target={target}
        ref={innerRef}
        noBlankIcon={noBlankIcon}
        {...otherProps}
      >
        {this.icon && !iconAppend && <Icon size={size} {...this.icon} />}
        {hasChildren && <Span>{children}</Span>}
        {target === '_blank' && !noBlankIcon && <Icon icon="external-link" />}
        {this.icon && iconAppend && <Icon size={size} {...this.icon} />}
      </BLink>
    )
  }
}
